import React, { forwardRef } from "react"
import styles from "./middle.module.css"
import Typo from "../typo"
import IconBrush from "../../images/icon-brush.svg"
import IconBadge from "../../images/icon-badge.svg"
import IconFace from "../../images/icon-face.svg"

const Middle = forwardRef((props, ref) => {
  return (
    <div className={styles.root} ref={ref}>
      <div className={styles.text}>
        <div>
          <Typo type="text600" component="h3">
            What we do
          </Typo>
          <Typo type="text300" component="p">
            We create digital experience, build brands, launch campaigns or
            giving innovative solutions to answer our clients’ needs.
          </Typo>
        </div>
        <div>
          <Typo type="text600" component="h3">
            How we do it
          </Typo>
          <Typo type="text300" component="p">
            We mix art and science to turn brands into favorites.
          </Typo>
        </div>
        <div>
          <Typo type="text600" component="h3">
            What makes us different
          </Typo>
          <Typo type="text300" component="p">
            We aim to build brands through distinctive design aesthetics that
            let our clients to set the industry benchmark.
          </Typo>
        </div>
      </div>

      <div className={styles.figure}>
        <div>
          <Typo
            type="title500"
            component="div"
            className={styles.figure__num}
          >
            2011
          </Typo>
          <Typo
            type="text600"
            component="div"
            className={styles.figure__text}
          >
            Since 2011, time flies
          </Typo>
          <div className={styles.figure__icon}>
            <IconBrush />
          </div>
        </div>
        <div>
          <Typo
            type="title500"
            component="div"
            className={styles.figure__num}
          >
            11
          </Typo>
          <Typo
            type="text600"
            component="div"
            className={styles.figure__text}
          >
            Awards for design and development
          </Typo>
          <div className={styles.figure__icon}>
            <IconBadge />
          </div>
        </div>
        <div>
          <Typo
            type="title500"
            component="div"
            className={styles.figure__num}
          >
            13
          </Typo>
          <Typo
            type="text600"
            component="div"
            className={styles.figure__text}
          >
            Talented team players
          </Typo>
          <div className={styles.figure__icon}>
            <IconFace />
          </div>
        </div>
      </div>
    </div>
  )
})

export default Middle