import React, { useState } from "react"
import styles from "./showreel.module.css"
import Typo from "../typo"
import Marquee from "../marquee"
import VideoPopup from "../video-popup"

const Showreel = () => {
  const [popupVisible, setPopupVisible] = useState(false)

  const handleClick = () => {
    setPopupVisible(true)
  }

  const handleCloseClick = () => {
    setPopupVisible(false)
  }

  return (
    <>
      <div className={styles.root} onClick={handleClick}>
        <Typo type="title600" component="h2">
          Watch our
        </Typo>
        <div className={styles.marquee}>
          <Marquee text="SHOWREEL" duration="10s" stroke />
        </div>
      </div>

      <VideoPopup
        visible={popupVisible}
        onCloseClick={handleCloseClick}
        src="https://www.youtube.com/embed/3wXK70a28Jw?autoplay=1&playsinline=1"
      />
    </>
  )
}

export default Showreel