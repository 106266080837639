import React, { useRef, useEffect } from "react"
import styles from "./photos.module.css"
import Typo from "../typo"

import photo1 from "../../images/photo_1.jpg"
import photo2 from "../../images/photo_2.jpg"
import photo3 from "../../images/photo_3.jpg"
import photo4 from "../../images/photo_4.jpg"
import photo5 from "../../images/photo_5.jpg"
import photo6 from "../../images/photo_6.jpg"
import photo7 from "../../images/photo_7.jpg"
import photo8 from "../../images/photo_8.jpg"
import photo9 from "../../images/photo_9.jpg"
import photo10 from "../../images/photo_10.jpg"
import photo11 from "../../images/photo_11.jpg"
import photo12 from "../../images/photo_12.jpg"
import photo13 from "../../images/photo_13.jpg"
import photo14 from "../../images/photo_14.jpg"
import photo15 from "../../images/photo_15.jpg"
import photo16 from "../../images/photo_16.jpg"

const pool = [
  [photo1, photo2, photo15, photo16],
  [photo5, photo8, photo9, photo10, photo13, photo14],
  [photo3, photo4, photo6, photo7, photo11, photo12],
]

const poolIndexes = [0, 0, 0]

const Photos = () => {
  const photosCanvas = useRef()

  useEffect(() => {
    let screen = window.innerWidth < 1024 ? "xs" : "lg"
    let prevScreen = screen

    const setCanvasSize = () => {
      photosCanvas.current.width = document.body.clientWidth
      photosCanvas.current.height = screen === "lg" ? 700 : 350
    }
    setCanvasSize()

    let counter = pool.reduce((result, arr) => result += arr.length, 0)

    const images = pool.map(arr => arr.map(src => {
      const image = new Image()

      image.onload = () => {
        counter--
        if (!counter) {
          init()
        }
      }

      image.src = src

      return image
    }))

    for (let i = 0; i < images.length; i++) {
      images[i].sort(() => Math.random() > 0.5 ? 1 : -1)
    }

    let rafId = null
    let tid = null

    const init = () => {
      const ctx = photosCanvas.current.getContext("2d")

      const sizeA = [350, 215]
      const sizeB = [350, 350]
      const sizeC = [350, 510]

      const combinations = [[sizeA, sizeB], [sizeB, sizeA], [sizeB], [sizeC]]

      let lastIndex = null

      let padding = 30

      if (screen === "xs") {
        sizeA[0] = sizeA[0] / 2
        sizeA[1] = sizeA[1] / 2
        sizeB[0] = sizeB[0] / 2
        sizeB[1] = sizeB[1] / 2
        sizeC[0] = sizeC[0] / 2
        sizeC[1] = sizeC[1] / 2
        padding = padding / 2
      }

      const shapes = []

      const makeShape = x => {
        let index = Math.floor(Math.random() * 4)
        while (index === lastIndex) {
          index = Math.floor(Math.random() * 4)
        }

        const combination = combinations[index]

        let count = combination.length
        let offset =
          index === 2
            ? Math.floor(Math.random() * 8) * padding
            : Math.floor(Math.random() * 4) * padding
        let y = 0 + offset

        while (count) {
          const [w, h] = combination[--count]
          const dr = w / h
          const k = dr > 1 ? 0 : dr === 1 ? 1 : 2

          const img = images[k][poolIndexes[k]]
          const sr = img.width / img.height

          let sx, sy, sw, sh

          if (sr > dr) {
            sh = img.height
            sw = dr * sh
            sy = 0
            sx = (img.width - sw) / 2
          } else if (sr < dr) {
            sw = img.width
            sh = sw / dr
            sx = 0
            sy = (img.height - sh) / 2
          } else {
            sw = img.width
            sh = img.height
            sx = 0
            sy = 0
          }

          shapes.push([img, sx, sy, sw, sh, x, y, w, h])

          y = h + padding + offset
          poolIndexes[k] = (poolIndexes[k] + 1) % pool[k].length
        }

        lastIndex = index
      }

      const drawShape = shape => {
        ctx.drawImage.apply(ctx, shape)
      }

      makeShape(0)
      while (shapes[shapes.length - 1][5] < document.body.clientWidth * 2) {
        makeShape(
          shapes[shapes.length - 1][5] + shapes[shapes.length - 1][7] + padding
        )
      }

      const speed = 1

      const update = () => {
        ctx.clearRect(
          0,
          0,
          document.body.clientWidth,
          photosCanvas.current.height
        )

        for (let i = 0; i < shapes.length; i++) {
          drawShape(shapes[i])
          shapes[i][5] -= speed
        }

        rafId = requestAnimationFrame(update)
      }

      update()

      const check = () => {
        for (let i = shapes.length - 1; i >= 0; i--) {
          if (shapes[i][5] < -shapes[i][7]) {
            shapes.splice(0, i)

            while (
              shapes[shapes.length - 1][5] <
              document.body.clientWidth * 2
            ) {
              makeShape(
                shapes[shapes.length - 1][5] +
                  shapes[shapes.length - 1][7] +
                  padding
              )
            }
          }
        }

        tid = setTimeout(check, 5000)
      }

      check()
    }

    const handleResize = () => {
      screen = window.innerWidth < 1024 ? "xs" : "lg"

      setCanvasSize()

      if (screen !== prevScreen) {
        cancelAnimationFrame(rafId)
        clearTimeout(tid)
        init()
        prevScreen = screen
      }
    }

    window.addEventListener("resize", handleResize)

    return () => {
      cancelAnimationFrame(rafId)
      clearTimeout(tid)
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <div className={styles.root}>
      <Typo type="title600" component="h2">
        Do what we Love, love what we do!
      </Typo>
      <Typo type="text300" component="p">
        We believe best work comes up when we have fun doing it.
        <br />
        Stay Happy! Stay Healthy is always our methodology.
      </Typo>

      <canvas ref={photosCanvas}></canvas>
    </div>
  )
}

export default Photos