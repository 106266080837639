import React from "react"
import styles from "./top.module.css"
import Typo from "../typo"

const Top = () => {
  return (
    <div className={styles.root}>
      <div>
        <Typo type="title500" component="h1">
          We are U<span>n</span>L<span>i</span>
          <span>m</span>
          <span>i</span>T<span>e</span>D.
          <br />a Creative agency specializing in digital innovation.
        </Typo>
        <Typo type="text300" component="p">
          Our team of consultants, designers and developers builds and launches
          digital products and experiences for ambitious clients.
        </Typo>
      </div>
    </div>
  )
}

export default Top