import React from "react"
import styles from "./clients.module.css"
import Typo from "../typo"

import client1 from "../../images/client_1.png"
import client2 from "../../images/client_2.png"
import client3 from "../../images/client_3.png"
import client4 from "../../images/client_4.png"
import client5 from "../../images/client_5.png"
import client6 from "../../images/client_6.png"
import client7 from "../../images/client_7.png"
import client8 from "../../images/client_8.png"
import client9 from "../../images/client_9.png"
import client10 from "../../images/client_10.png"
import client11 from "../../images/client_11.png"
import client12 from "../../images/client_12.png"
import client13 from "../../images/client_13.png"
import client14 from "../../images/client_14.png"
import client15 from "../../images/client_15.png"
import client16 from "../../images/client_16.png"
import client17 from "../../images/client_17.png"
import client18 from "../../images/client_18.png"
import client19 from "../../images/client_19.png"
import client20 from "../../images/client_20.png"
import client21 from "../../images/client_21.png"
import client22 from "../../images/client_22.png"
import client23 from "../../images/client_23.png"
import client24 from "../../images/client_24.png"

const clientSources = [
  client1,
  client2,
  client3,
  client4,
  client5,
  client6,
  client7,
  client8,
  client9,
  client10,
  client11,
  client12,
  client13,
  client14,
  client15,
  client16,
  client17,
  client18,
  client19,
  client20,
  client21,
  client22,
  client23,
  client24,
]

const Clients = () => {
  return (
    <div className={styles.root}>
      <Typo type="title600" component="h2">
        Brands who trust our work
      </Typo>
      <Typo type="text300" component="p">
        We know the world is full of choices. <br />
        Thank you for selecting us.
      </Typo>
      <div className={styles.logo_wrapper}>
        {clientSources.map((src, i) => (
          <div key={i} className={styles.logo}>
            <img src={src} alt={`client-${i + 1}`} />
          </div>
        ))}
      </div>
      <Typo type="text300" component="div" className={styles.more}>
        and more...
      </Typo>
    </div>
  )
}

export default Clients