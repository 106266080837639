import React, { useRef, useEffect, useState } from "react"
import Layout from "../components/layout"
import styles from "./about.module.css"
import SEO from "../components/seo"
import Top from "../components/about/top"
import Middle from "../components/about/middle"
import Photos from "../components/about/photos"
import Clients from "../components/about/clients"
import Showreel from "../components/about/showreel"

const About = () => {
  const middleEl = useRef()
  const [imgStyle, setImgStyle] = useState({})

  useEffect(() => {
    const handler = () => {
      if (window.innerWidth < 1024) {
        setImgStyle({})
        return
      }

      const y = window.pageYOffset
      const wh = window.innerHeight

      const middleRect = middleEl.current.getBoundingClientRect()
      const bottom = middleRect.top + middleRect.height

      if (0 <= y && wh < bottom) {
        setImgStyle({ position: "fixed" })
      } else {
        setImgStyle({ position: "absolute", top: y + bottom - wh + "px" })
      }
    }

    window.addEventListener("resize", handler)
    window.addEventListener("scroll", handler)

    return () => {
      window.removeEventListener("resize", handler)
      window.removeEventListener("scroll", handler)
    }
  }, [])

  return (
    <Layout>
      <SEO title="About" />

      <div className={styles.image} style={imgStyle}></div>

      <Top />
      <Middle ref={middleEl} />
      <Photos />
      <Clients />
      <Showreel />
    </Layout>
  )
}

export default About